
export const URL_PREFIX = "/app/api/v1";

export const supportLocales = [
    {id: 1, value: "en-US", text: "English"},
    {id: 0, value: "fr-FR", text: "Français"},
    {id: 2, value: "pt-PT", text: "Português"},
];

export const defaultLang = "en-US";
export const languageKey = "wiiwewekkeiwe";
export const localTokenKey = "siidkfkkdskllfdk";
export const localUserInfoKey = "ildksfkfdsafidhf";


export const request_info_deviceNumber = "siidkfkkdskllfdk";
export const request_info_appId = "com.d.c.mill.philippines";
export const request_info_market = "debug";